import logger from './logger';

/**
 * Gets the parameters for InitJS or for the plugins
 */
export default {
  /**
   * On referer change
   * @param {Function} cb
   * @returns {void}
   */
  onRefererChange: (cb) => {
    let currentReferer = window.location.href;

    if (window.self !== window.top) {
      logger.log(
        'warn',
        'onRefererChange will not be triggered because init js has been loaded in an iframe'
      );
      return false;
    }
    setInterval(() => {
      if (currentReferer === window.location.href) {
        return undefined;
      }
      currentReferer = window.location.href;
      return cb();
    }, 1000);
    return true;
  },

  /**
   * Finds the webpage referer
   * handles debug pmReferer parameter
   * @returns {string}    The referer if found
   * @returns {null}      If the referer was not found
   */
  getReferer: () => {
    const parsed = new URL(window.location.href);

    const pmReferer = parsed.searchParams.get('pmReferer');
    if (pmReferer) {
      logger.log(
        'warn',
        `pmReferer parameter is in the URL : referer will be forced to ${pmReferer}`
      );
      return pmReferer;
    }

    return window.location.href;
  },

  /**
   * Gets an installation parameter
   * For the moment, it can be provided only in the script tag, as an attribute
   * @param {String} key Key of the parameter
   * @param {NodeDom} scriptDomNode Current script tag DOM node
   * @returns {String}    Returns the value if found
   * @returns {undefined} If parameter not found
   */
  getParameter(currentScript, key) {
    if (currentScript.hasAttribute(key)) {
      return currentScript.getAttribute(key);
    }

    return undefined;
  },

  /**
   * Gets an accepted cookies array from the script tag parameter data-accepted-cookies
   * @param {NodeDom} scriptDomNode Current script tag DOM node
   * @returns {Array<String>} Accepted cookie keys
   */
  getAcceptedCookies(scriptDomNode) {
    const param = this.getParameter(scriptDomNode, 'data-accepted-cookies');
    if (typeof param === 'string') {
      return this.getParameter(scriptDomNode, 'data-accepted-cookies')
        .split(',')
        .map((key) => String(key).trim());
    }

    return [];
  },
};
