/**
 * Keys to identify set of cookies used in Pathmotion Apps.
 * Those sets are usually related to a Pathmotion App or Field of work.
 */
export default {
  /**
   * Cookies to provide a consistent experience by remembering settings, sessions and already viewed
   * content
   */
  CATEGORY_FUNCTIONAL: 'functional',

  /**
   * Cookies related to analytics tools (Google Analytics, Mixpanel...)
   */
  CATEGORY_ANALYTICS: 'analytics',

  /**
   * Social media partners cookies
   */
  CATEGORY_SOCIAL: 'social',
};
