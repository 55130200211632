import logger from './logger';

/**
 * DOM manipulation helper
 */
export default {
  /**
   * Injects a script tag at the end of the body
   * @param {string} src The script source attribute
   */
  appendScriptToBody({
    src,
    crossorigin = false,
    onLoad = () => {},
    dataset = {},
    async = false,
    defer = false,
  }) {
    if (src === undefined) {
      logger.log('error', 'No src attribute has been set. Unable to append the script to body.');
    }

    const tag = document.createElement('script');
    tag.type = 'text/javascript';
    tag.src = src;
    tag.charset = 'UTF-8';

    if (async) {
      tag.async = true;
    }

    if (defer) {
      tag.defer = true;
    }

    if (crossorigin) {
      tag.setAttribute('crossorigin', crossorigin);
    }

    if (onLoad) {
      tag.onload = onLoad;
    }

    Object.keys(dataset).forEach((key) => {
      tag.dataset[key] = dataset[key];
    });

    const node = document.body.appendChild(tag);

    logger.log('debug', 'Appending a script tag to the body', { src, node });

    return tag;
  },

  /**
   * Gets the script tag node that loaded the current running script
   * It uses the standard document.currentScript method if it is available (more reliable),
   * or it searches for the script that loads init.min.js as a fallback.
   * @returns {Node} The script tag
   */
  getCurrentScript() {
    logger.log('debug', 'Searching InitJS script tag in the dom');

    let currentScript;
    if (document.currentScript) {
      logger.log('debug', 'document.currentScript attribute has a value, using this value');
      currentScript = document.currentScript; // eslint-disable-line
    } else {
      logger.log(
        'debug',
        'document.currentScript attribute has no value, using a querySelector as a fallback'
      );
      currentScript = document.querySelector('script[src*="init.min.js"]');

      if (!currentScript) {
        logger.log(
          'debug',
          "The querySelector fallback didn't find init.min.js, trying to find fbapppromobox.js"
        );
        currentScript = document.querySelector('script[src*="fbapppromobox.js"]');
      }
    }

    if (!currentScript) {
      logger.log('error', 'Unable to locate the script tag');
      return undefined;
    }

    logger.log('debug', 'InitJS script tag found in the dom', { node: currentScript });

    return currentScript;
  },

  /**
   * Gets the URL object from a script src attribute
   * @param {DomNode} scriptDomNode The <script> dom node to get the url from
   * @returns {Url} The url object of the current script
   */
  getScriptUrl(scriptDomNode) {
    const scriptUrl = new URL(scriptDomNode.src);

    return scriptUrl;
  },
};
