/* eslint-disable */
const PREFIX = '[🏴󠁧󠁢󠁳󠁣󠁴󠁿Pathmotion InitJS][%instanceId]';

/**
 * Handles the logging of the initialization part
 */
class Logger {
  constructor() {
    this.instanceId = this.generateId();
    this.formattedPrefix = PREFIX.replace('%instanceId', this.instanceId);
  }

  generateId() {
    return Math.random().toString(36).substring(7);
  }

  log(level, ...messages) {
    if (!['debug', 'info', 'warn', 'error'].includes(level)) {
      console.error(`"${level}" is not a valid logging level. Using info as a fallback`);
    }

    switch (level) {
      case 'debug':
        if (window.pathmotionDebug) {
          console.debug(this.formattedPrefix, ...messages);
        }
        break;

      case 'info':
      default:
        console.log(this.formattedPrefix, ...messages);
        break;

      case 'warn':
        console.warn(this.formattedPrefix, ...messages);
        break;

      case 'error':
        console.error(this.formattedPrefix, ...messages);
        break;
    }
  }
}

export default new Logger();
