
        var result = require("!!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[0].use[1]!../../../node_modules/postcss-loader/dist/cjs.js!../../../node_modules/resolve-url-loader/index.js!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[0].use[4]!./badge.scss?raw");

        if (result && result.__esModule) {
            result = result.default;
        }

        if (typeof result === "string") {
            module.exports = result;
        } else {
            module.exports = result.toString();
        }
    