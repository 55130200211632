/* eslint-disable */
/**
 * Promobox
 * Script responsible for injecting integration iframes on divs with class _pathmotion_plugin_js
 *
 * Migrated from the monolith on 11/2019. In global scope because of the calls from the monolith
 * and our clients websites.
 */

import logger from '../init/logger';
import {
  getAppboxUrl,
  getChatboxUrl,
  getPromoboxUrl,
  getEngagementboxUrl,
  getVideoboxUrl,
  getDiscussionsboxUrl,
} from './urls';

/**
 * Convert string to array
 *
 * @param {string} str
 *      Example '['Application process']'
 * @return {string[]}
 */
function stringToArray(str) {
  return eval(str).map((item) => (Array.isArray(item) ? item[0] : item));
}

// This is an ugly hack because the backend is calling pminit directly
function dispatchPromoboxLoadedEvent() {
  var event;
  if (typeof Event === 'function') {
    event = new Event('promobox_loaded');
  } else {
    event = window.document.createEvent('Event');
    event.initEvent('promobox_loaded', true, true);
  }

  window.document.dispatchEvent(event);
}

export function load() {
  var debugMode;
  window.iFrameLoadedCallback = function () {};
  window.iFrameLoadedScrollCallback = function () {};

  var pmGetParam = function (name, tell_if_undefined) {
    if (tell_if_undefined == undefined || tell_if_undefined !== true) tell_if_undefined = false;
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regexS = '[\\?&]' + name + '=([^&#]*)';
    var regex = new RegExp(regexS);
    var results = regex.exec(window.location.href);
    if (results == null) return tell_if_undefined ? undefined : '';
    else return results[1];
  };
  var _pmlogger = function (...args) {
    logger.log('debug', ...args);
  };

  _pmlogger('Hi from pmlogger (v4)!');
  /*
      pmGetElementsByClassName
      Developed by Robert Nyman, http://www.robertnyman.com
      Code/licensing: http://code.google.com/p/getelementsbyclassname/
      */
  window.pmGetElementsByClassName = function (className, tag, elm) {
    let getElementsByClassName;

    if (document.getElementsByClassName) {
      getElementsByClassName = function (className, tag, elm) {
        elm = elm || document;
        var elements = elm.getElementsByClassName(className),
          nodeName = tag ? new RegExp('\\b' + tag + '\\b', 'i') : null,
          returnElements = [],
          current;
        for (var i = 0, il = elements.length; i < il; i += 1) {
          current = elements[i];
          if (!nodeName || nodeName.test(current.nodeName)) {
            returnElements.push(current);
          }
        }
        return returnElements;
      };
    } else if (document.evaluate) {
      getElementsByClassName = function (className, tag, elm) {
        tag = tag || '*';
        elm = elm || document;
        var classes = className.split(' '),
          classesToCheck = '',
          xhtmlNamespace = 'http://www.w3.org/1999/xhtml',
          namespaceResolver =
            document.documentElement.namespaceURI === xhtmlNamespace ? xhtmlNamespace : null,
          returnElements = [],
          elements,
          node;
        for (var j = 0, jl = classes.length; j < jl; j += 1) {
          classesToCheck += "[contains(concat(' ', @class, ' '), ' " + classes[j] + " ')]";
        }
        try {
          elements = document.evaluate(
            './/' + tag + classesToCheck,
            elm,
            namespaceResolver,
            0,
            null
          );
        } catch (e) {
          elements = document.evaluate('.//' + tag + classesToCheck, elm, null, 0, null);
        }
        while ((node = elements.iterateNext())) {
          returnElements.push(node);
        }
        return returnElements;
      };
    } else {
      getElementsByClassName = function (className, tag, elm) {
        tag = tag || '*';
        elm = elm || document;
        var classes = className.split(' '),
          classesToCheck = [],
          elements = tag === '*' && elm.all ? elm.all : elm.getElementsByTagName(tag),
          current,
          returnElements = [],
          match;
        for (var k = 0, kl = classes.length; k < kl; k += 1) {
          classesToCheck.push(new RegExp('(^|\\s)' + classes[k] + '(\\s|$)'));
        }
        for (var l = 0, ll = elements.length; l < ll; l += 1) {
          current = elements[l];
          match = false;
          for (var m = 0, ml = classesToCheck.length; m < ml; m += 1) {
            match = classesToCheck[m].test(current.className);
            if (!match) {
              break;
            }
          }
          if (match) {
            returnElements.push(current);
          }
        }
        return returnElements;
      };
    }
    return getElementsByClassName(className, tag, elm);
  };
  // First plugin init
  // *****************
  _pmlogger('====> document.domain = "' + document.domain + '"');
  var targetDocument = document;
  var domain = document.domain;
  var pmInsertNodeCounter = 0;
  var pmInsertNode = function (node, where, callback) {
    if (typeof where.appendChild == 'function') {
      where.appendChild(node);
    } else {
      // old IE
      where.insertAdjacentElement('beforeEnd', node);
    }
    node.setAttribute('data-node', ++pmInsertNodeCounter);
    if (typeof callback == 'function') {
      callback();
    }
    return node.getAttribute('id');
  };
  window.pmCreateNode = function (nodeType, nodeAttributes, nodeContent, targetDocument) {
    var newNode = targetDocument.createElement(nodeType);
    if (typeof nodeContent != 'undefined' && typeof nodeContent['content'] != 'undefined') {
      if (typeof nodeContent['html'] == 'undefined' || nodeContent['html'] == true) {
        // html content
        newNode.innerHTML = nodeContent['content'];
      } else {
        // text content
        if (typeof newNode.appendChild == 'function') {
          newNode.appendChild(targetDocument.createTextNode(nodeContent['content']));
        } else {
          _pmlogger('ERROR : appendChild() not available!');
        }
      }
    }
    newNode.setAttribute('id', 'pmCreatedNode' + Math.floor(Math.random() * 10000000000000) + '');
    for (var k in nodeAttributes) {
      newNode.setAttribute(k, nodeAttributes[k]);
    }
    return newNode;
  };

  var iframeID = '';
  window._pmPluginInsert = function (type, options) {
    var pmrand = Math.floor(Math.random() * 10000000000000) + '';
    var frame = type + pmrand;
    iframeID = type + pmrand;
    var iframe_to_render = '';
    var iframeAttributes = {
      id: frame,
      width: '100%',
      style:
        'background:transparent;overflow:hidden;' +
        '-webkit-transition: height 1s;-moz-transition: height 1s;-moz-transition: height 1s;-ms-transition: height 1s;transition: height 1s;' +
        'height:' +
        (debugMode || type === 'pmappbox' ? '4000px' : '0px'),
      frameborder: '0',
      allowtransparency: 'true',
      scrolling: 'no',
      seamless: 'seamless',
      class: '',
      allow: 'clipboard-read; clipboard-write',
      onload: 'pmFrameInit(this);',
    };

    if (options && options.attributes && options.attributes instanceof Object) {
      Object.assign(iframeAttributes, options.attributes);
    }

    _pmlogger(type + ' v4 - containerId => ' + options['containerId']);

    // complement URL with frame id
    options['iframe_url'] = options['iframe_url'] + '&frame=' + frame;
    _pmlogger(type + ' => Init plugin iframe ' + options['iframe_url']);

    iframe_to_render = pmCreateNode('iframe', iframeAttributes, '', options['targetDocument']);

    if (options['targetDocument'].getElementById(options['containerId'])) {
      if (
        typeof options['targetDocument'].getElementById(options['containerId']).appendChild ==
        'function'
      ) {
        options['targetDocument']
          .getElementById(options['containerId'])
          .appendChild(iframe_to_render);
      } else {
        // old IE
        options['targetDocument']
          .getElementById(options['containerId'])
          .insertAdjacentElement('beforeEnd', iframe_to_render);
      }
    }
    /* Setting URL after the DOM insertion to avoid browser back issue on Chrome/Opera that load the old iframe URL instead of the new generated one */
    options['targetDocument'].getElementById(frame).setAttribute('src', options['iframe_url']);
  };

  // ----------------------------------------------------------------------------------------------------------------

  window._pmappbox = function (pmappbox) {
    // full app external plugin
    if (typeof pmappbox['targetDocument'] == 'undefined') {
      // for Deprecated v2 calls
      pmappbox['targetDocument'] = targetDocument;
    }
    if (pmappbox['app']) {
      var app_data = decodeURIComponent(pmGetParam('app_data'));
      if (app_data == undefined && pmappbox['app_data'] != undefined) {
        app_data = pmappbox['app_data'];
      }
      pmappbox['iframe_url'] = getAppboxUrl({
        appUrl: pmappbox['app'],
        initialPath: app_data,
        land: pmappbox['land'],
        enableDebug: debugMode,
        refererLocation: pmappbox['targetDocument'].location,
      });

      pmappbox['attributes'] = {
        title: 'Discussions',
        ...pmappbox.attributes,
      };

      _pmPluginInsert('pmappbox', pmappbox);
    }
  };

  window._pmchatbox = function (pmchatbox, async) {
    setTimeout(function () {
      // Live Chat promotional plugin
      if (typeof pmchatbox['targetDocument'] == 'undefined') {
        // for Deprecated v2 calls
        pmchatbox['targetDocument'] = targetDocument;
      }
      if (pmchatbox['app']) {
        pmchatbox['iframe_url'] = getChatboxUrl({
          appUrl: pmchatbox['app'],
          land: pmchatbox['land'],
          chat: pmchatbox['chat'],
          title: pmchatbox['title'],
          enableDebug: debugMode,
          refererLocation: pmchatbox['targetDocument'].location,
        });

        _pmPluginInsert('pmchatbox', pmchatbox);
      }
    }, 150);
  };

  window._pmpromobox = function (pmpromobox, async) {
    setTimeout(function () {
      // Multi-content plugin promotion (insiders, last discussions, etc.)
      if (typeof pmpromobox['targetDocument'] == 'undefined') {
        // for Deprecated v2 calls
        pmpromobox['targetDocument'] = targetDocument;
      }
      if (pmpromobox['fbpage'] || pmpromobox['app']) {
        // if (pmpromobox['comments'] == undefined){
        // 	pmpromobox['comments'] = true;
        // }
        var insiders_params = '';
        var categories_params = '';

        let nbInsidersShown;
        if (
          pmpromobox['insiders'] != undefined &&
          typeof eval(pmpromobox['insiders']) === 'object'
        ) {
          // an array is passed with ID of requested insiders
          var insiders_arr = eval(pmpromobox['insiders'])
            .map((item) => {
              if (Array.isArray(item)) {
                if (item.length === 0) {
                  return null;
                }
                return item.shift();
              }
              return item;
            })
            .filter((item) => typeof item === 'string' || typeof item === 'number');
          for (var i = 0; insiders_arr[i]; i++)
            insiders_params = insiders_params + '&insiders[]=' + insiders_arr[i];

          // force disabling comments
          if (typeof pmpromobox['comments'] == 'undefined' || pmpromobox['comments'] == null) {
            pmpromobox['comments'] = false;
          }
        } else if (pmpromobox['insiders'] != undefined) {
          nbInsidersShown = pmpromobox['insiders'];
          insiders_params = '&insiders=' + pmpromobox['insiders'];
        }

        let nbCategoriesShown;
        if (
          pmpromobox['categories'] != undefined &&
          typeof eval(pmpromobox['categories']) === 'object'
        ) {
          // an array is passed with URLIDs of requested categories filter
          var cat_arr = eval(pmpromobox['categories'])
            .map((item) => {
              if (Array.isArray(item)) {
                if (item.length === 0) {
                  return null;
                }
                return item.shift();
              }
              return item;
            })
            .filter((item) => typeof item === 'string');
          for (var i = 0; cat_arr[i]; i++)
            categories_params = categories_params + '&categories[]=' + cat_arr[i];
        } else if (pmpromobox['categories'] != undefined) {
          nbCategoriesShown = pmpromobox['categories'];
          categories_params = '&categories=' + pmpromobox['categories'];
        }

        pmpromobox['iframe_url'] = getPromoboxUrl({
          appUrl: pmpromobox['app'],
          land: pmpromobox['land'],
          title: pmpromobox['title'],
          enableDebug: debugMode,
          nbCommentsShown: pmpromobox['comments'],
          nbInsidersShown,
          nbCategoriesShown,
          insidersIdsToShow: insiders_arr,
          categoriesIdsToShow: cat_arr,
          refererLocation: pmpromobox['targetDocument'].location,
          promoteLivechat: pmpromobox['promoteLivechat'],
        });

        _pmPluginInsert('pmpromobox', pmpromobox);
      }
    }, 150);
  };

  /**
   * video plugin
   *
   * @param {Object} pmvideobox
   */
  window._pmvideobox = function (pmvideobox) {
    setTimeout(() => {
      if (pmvideobox['app'] && pmvideobox['videos'] != undefined) {
        const videos = eval(pmvideobox['videos']).map((item) => item[0] || item);
        pmvideobox['iframe_url'] = getVideoboxUrl({
          appUrl: pmvideobox['app'],
          land: pmvideobox['land'],
          title: pmvideobox['title'],
          autoplay: pmvideobox['autoplay'],
          videotarget: pmvideobox['videotarget'],
          enableDebug: debugMode,
          videos,
          refererLocation: pmvideobox['targetDocument'].location,
          containerId: pmvideobox['containerId'],
          promoteLivechat: pmvideobox['promoteLivechat'],
        });

        pmvideobox['attributes'] = {
          allowfullscreen: 'true',
          allow: 'autoplay',
        };

        _pmPluginInsert('pmvideobox', pmvideobox);
      }
    }, 150);
  };

  /**
   * Discussions plugin
   *
   * @param {Object} pmdiscussionsbox
   * @private
   */
  window._pmdiscussionsbox = function (pmdiscussionsbox) {
    const {
      app: appUrl,
      land,
      title,
      targetDocument,
      search,
      discussionsNumber: nbDiscussionsShown,
      unlimitedDiscussions,
    } = pmdiscussionsbox;

    let greatTopics;
    let pmsParentCategories;
    let topics;
    if (pmdiscussionsbox.greatTopics) {
      greatTopics = stringToArray(pmdiscussionsbox.greatTopics);
    }
    if (pmdiscussionsbox.pmsParentCategories) {
      pmsParentCategories = stringToArray(pmdiscussionsbox.pmsParentCategories);
    }
    if (pmdiscussionsbox.topics) {
      topics = stringToArray(pmdiscussionsbox.topics);
    }

    setTimeout(function () {
      if (pmdiscussionsbox.app) {
        pmdiscussionsbox.iframe_url = getDiscussionsboxUrl({
          appUrl,
          land,
          title,
          nbDiscussionsShown,
          greatTopics,
          search,
          pmsParentCategories,
          topics,
          enableDebug: debugMode,
          refererLocation: targetDocument.location,
          unlimitedDiscussions,
        });

        _pmPluginInsert('pmdiscussionsbox', pmdiscussionsbox);
      }
    }, 150);
  };

  window._pmengagementbox = function (pmengagementbox, async) {
    setTimeout(function () {
      if (typeof pmengagementbox['targetDocument'] == 'undefined') {
        // for Deprecated v2 calls
        pmengagementbox['targetDocument'] = targetDocument;
      }

      var forceRendering = pmGetParam('force-engagement-push', true);

      // User re-engagement plugin
      if (pmengagementbox['app']) {
        pmengagementbox['iframe_url'] = getEngagementboxUrl({
          appUrl: pmengagementbox['app'],
          land: pmengagementbox['land'],
          loggedUser: pmengagementbox['user'],
          render: pmengagementbox['render'],
          forceShowing: forceRendering,
          title: pmengagementbox['title'],
          refererLocation: pmengagementbox['targetDocument'].location,
        });

        _pmPluginInsert('pmengagementbox', pmengagementbox);
      }
    }, 3000);
  };

  // -------------------------------------------------------------------------------------------------

  // Plugin(s) DOM initialisation
  var pmOnloaded = false;
  window._pminit = function (context) {
    var pmContainers = pmGetElementsByClassName('_pathmotion_plugin_js');
    if (pmContainers.length) {
      if (pmOnloaded) {
        return;
      }

      // pmOnloaded = true;
      _pmlogger('_pminit ' + pmContainers.length + ' item(s) (' + context + ')...');
      for (var i = 0; i < pmContainers.length; ++i) {
        if (pmContainers[i].getAttribute('_js_enabled')) {
          continue;
        }

        pmContainers[i].setAttribute('_js_enabled', 'true');
        var pmrand = Math.floor(Math.random() * 10000000000000) + '';
        var pmContainerId = 'pmContainerBox' + pmrand;
        var pmPluginType = pmContainers[i].getAttribute('data-type');
        var targetLand = pmContainers[i].getAttribute('data-land');

        if (document.getElementById(pmContainerId)) {
          continue;
        }

        const availablePlugins = [
          '_pmchatbox',
          '_pmpromobox',
          '_pmappbox',
          '_pmengagementbox',
          '_pmvideobox',
          '_pmdiscussionsbox',
        ];

        if (availablePlugins.indexOf(pmPluginType) > -1) {
          if (pmPluginType == '_pmengagementbox') {
            /** _pmengagementbox will not be able to be shown under a Facebook platform installation. **/
            if (
              typeof window.parent !=
                'undefined' /* && typeof window.parent.parent != 'undefined'*/ &&
              typeof window.parent._pmengagementbox != 'undefined' &&
              document.getElementById('fb-root') &&
              document.getElementById('fb-root').getAttribute('rel')
            ) {
              /* here we might be into a framed platform so we cannot handled a visible bottom fixed position of the modal */
              /* so you move the current document to the parent window document hosting the appbox plugin */
              targetDocument = window.parent.document;
              if (!targetLand) {
                targetLand = document.getElementById('fb-root').getAttribute('rel');
              }
            }
            var hostCostigan = removeSufixFromHost(pmContainers[i].getAttribute('data-app'));
            pmInsertNode(
              pmCreateNode(
                'link',
                {
                  rel: 'stylesheet',
                  href: 'https://' + hostCostigan + '/v3/stylesheets/costigan.css',
                },
                '',
                targetDocument
              ),
              targetDocument.head
            );

            var pmCostiganPanelID = pmInsertNode(
              pmCreateNode(
                'div',
                {
                  class: 'pmCostiganPanel' + (window.self !== window.top ? ' within-frame' : ''),
                  style: '',
                },
                {
                  html: true,
                  content: '<div id="' + pmContainerId + '"></div>',
                },
                targetDocument
              ),
              targetDocument.body
            );
            /**
             * @todo: those "iFrameLoadedCallback" and "iFrameLoadedScrollCallback" below could deal only with one plugin
             * to manage multiple plugins on the same page being able to use that,
             * we should update iFrameLoadedCallback() to be able to save a collection of function to execute
             */
            var pmCostiganPanelClass = targetDocument.getElementById(pmCostiganPanelID).className;
            window.iFrameLoadedCallback = function () {
              logger.log('debug', {
                pmCostiganPanelID,
                targetDocument,
                pmCostiganPanelClass,
                iFrameLoadedScrollCallback: window.iFrameLoadedScrollCallback,
              });
              targetDocument.getElementById(pmCostiganPanelID).className =
                pmCostiganPanelClass + ' ' + 'ReadyToSlideToBeVisible';
              if (pmCostiganPanelClass.test('within-frame')) {
                // embed platform does not fired the window.onscroll, so directly show the panel
                window.iFrameLoadedScrollCallback();
              }
            };
            window.iFrameLoadedScrollCallback = function () {
              var pmContainersCostiganReady = pmGetElementsByClassName('ReadyToSlideToBeVisible');
              for (var i = 0; i < pmContainersCostiganReady.length; ++i) {
                pmContainersCostiganReady[i].className = pmCostiganPanelClass;
                setTimeout(function () {
                  /**
                   * adding className "slideToBeVisible" to element with className "ReadyToSlideToBeVisible"
                   */
                  targetDocument.getElementById(pmCostiganPanelID).className =
                    pmCostiganPanelClass + ' ' + 'slideToBeVisible';
                }, 2000);
              }
            };
          } else {
            pmContainers[i].setAttribute('id', pmContainerId);
          }

          var pluginAppOptions = {
            targetDocument: targetDocument,
            containerId: pmContainerId,
            app: pmContainers[i].getAttribute('data-app'),
            land: targetLand,
            comments: pmContainers[i].getAttribute('data-comments'),
            insiders: pmContainers[i].getAttribute('data-insiders'),
            height: pmContainers[i].getAttribute('data-height'),
            title: encodeURIComponent(pmContainers[i].getAttribute('data-title')),
            button: pmContainers[i].getAttribute('data-button'),
            slider: pmContainers[i].getAttribute('data-slider'),
            categories: pmContainers[i].getAttribute('data-categories'),
            user: pmContainers[i].getAttribute('data-user'),
            render: pmContainers[i].getAttribute('data-render'),
            videos: pmContainers[i].getAttribute('data-videos'),
            autoplay: pmContainers[i].getAttribute('data-autoplay'),
            videotarget: pmContainers[i].getAttribute('data-videotarget'),
            discussionsNumber: pmContainers[i].getAttribute('data-discussions-number'),
            search: pmContainers[i].getAttribute('data-search'),
            greatTopics: pmContainers[i].getAttribute('data-great-topics'),
            pmsParentCategories: pmContainers[i].getAttribute('data-pms-categories'),
            topics: pmContainers[i].getAttribute('data-topics'),
            promoteLivechat: pmContainers[i].getAttribute('data-promote-livechat') !== 'false',
            unlimitedDiscussions:
              pmContainers[i].getAttribute('data-unlimited-discussions') !== 'false',
          };

          _pmlogger('#' + (i + 1) + ': ' + pmPluginType, {
            pmPluginType,
            container: pmContainers[i],
            app: pmContainers[i].getAttribute('data-app'),
            options: pluginAppOptions,
          });
          eval(pmPluginType)(pluginAppOptions);
        } else {
          alert('Error: Unknow PathMotion plugin : ' + pmPluginType + '.');
        }
      }
    }

    if (typeof iFrameResize == 'undefined') {
      pmInsertNode(
        pmCreateNode(
          'script',
          {
            type: 'text/javascript',
            src: 'https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/3.6.1/iframeResizer.min.js',
          },
          '',
          targetDocument
        ),
        targetDocument.body
      );
    }
  };

  // add message listener from iframe to parent window
  if (window.addEventListener) {
    window.addEventListener('message', onMessage, false);
  } else if (window.attachEvent) {
    window.attachEvent('onmessage', onMessage, false);
  }

  /**
   * Callback for "onmessage" event
   * @param event
   */
  var initialPageLoad = true;
  function onMessage(event) {
    const data = event.data;

    // When the resizer is ready (iframe content window broadcasting its loaded)
    // Scroll the page with the iframe embed to the top the of the iframe
    if (data === '[iFrameResizerChild]Ready') {
      if (!initialPageLoad) {
        var iframeTop =
          document.getElementById(iframeID).getBoundingClientRect().top + window.scrollY;

        window.scrollTo({
          top: Math.max(iframeTop - 120, 0), // little offset of 120 to counteract browser inconsistencies / sticky or fixed overlays
        });
      }

      initialPageLoad = false;
    }

    if (typeof window[data.func] === 'function') {
      window[data.func].call(null, data.message);
    }
  }

  /**
   * showVideoPluginPopup create popup for video plugin
   *
   * @param {Object} data
   */
  window.showVideoPluginPopup = function (data) {
    if (data instanceof Object && data.pmvideoboxId) {
      const container = document.getElementById(data.pmvideoboxId);
      if (container) {
        const div = document.createElement('div');
        div.id = data.wrapContainerId || 'pmVideoPluginPopupWrap';
        div.innerHTML = data.html ? data.html : '';
        container.appendChild(div);
      }
    }
  };

  window.pmContainsObject = function (obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i] === obj) {
        return true;
      }
    }
    return false;
  };

  var pmFramesInitialised = [];
  window.pmFrameInit = function (frame) {
    if (pmFramesInitialised.indexOf(frame.id) === -1) {
      pmFrameResize(frame.id);
      pmFramesInitialised.push(frame.id);
    }
  };

  var pmFramesResized = [];
  window.pmFrameResize = function (frameId) {
    if (typeof iFrameResize === 'undefined') {
      setTimeout(function () {
        pmFrameResize(frameId);
      }, 300);
      return false;
    }
    if (!pmContainsObject(frameId, pmFramesResized)) {
      pmFramesResized.push(frameId);

      var frame = iFrameResize(
        {
          warningTimeout: 0,
          checkOrigin: false,
          autoResize: true,
          heightCalculationMethod:
            navigator.userAgent.indexOf('MSIE') !== -1 ? 'max' : 'bodyOffset',
          scrollCallback: function (e) {
            _pmlogger('pmbox::scrollCallback', e);
            return true;
          },
          resizedCallback: function (e) {
            if (e.type == 'resetPage') {
              return false;
            }
            _pmlogger('pmbox::resizedCallback', e);
            return true;
          },
          messageCallback: function (e) {
            _pmlogger('pmbox::messageCallback', e);
            return true;
          },
        },
        '#' + frameId
      );
    } else {
      _pmlogger(frameId + ' already resized.');
    }
    return true;
  };

  window.removeSufixFromHost = function (host) {
    if (host.indexOf('/') === -1) {
      return host;
    }
    return host.slice(0, host.indexOf('/'));
  };

  function runWhenWindowLoaded(runnable) {
    if (document.readyState === 'complete') {
      runnable();
    } else {
      window.addEventListener('load', runnable);
    }
  }

  runWhenWindowLoaded(function () {
    _pminit('load');
    if (typeof window.iFrameLoadedCallback === 'function') {
      window.iFrameLoadedCallback();
    }
  });

  window.addEventListener('scroll', function () {
    if (typeof window.iFrameLoadedScrollCallback === 'function') {
      window.iFrameLoadedScrollCallback();
    }
  });
  _pminit('direct');
  dispatchPromoboxLoadedEvent();
  const observer = new MutationObserver(() => {
    setTimeout(() => {
      if (document.querySelectorAll('._pathmotion_plugin_js:not([_js_enabled])').length) {
        _pminit('observer');
      }
    }, 1000);
  });
  observer.observe(document.body, {
    // attributeFilter
    attributeOldValue: true,
    attributes: true,
    characterData: true,
    characterDataOldValue: true,
    childList: true,
    subtree: true,
  });
}
