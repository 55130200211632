import eventBus from '../init/eventBus';
import Events from './events';
import CookieKeys from './cookieKeys';

/**
 * Handles the pmDebug URL parameter by enabling pathmotionDebug
 */
const parsed = new URL(window.location.href);

const pmDebug = parsed.searchParams.get('pmDebug');
if (pmDebug) {
  console.warn(
    'pmDebug query parameter is in the URL - window.pathmotionDebug will be set to true'
  );
  window.pathmotionDebug = true;
}

/**
 * Allows the external developers to get a debug mode (for InitJS only)
 */
const debug = {
  enable: () => {
    console.warn('Enabling debug mode by setting window.pathmotionDebug to true');
    window.pathmotionDebug = true;
  },
};

/**
 * Triggers the acceptance of a pathmotion apps cookie.
 * This usually loads an app or removes a cookie consent screen
 * as it has already been accepted.
 * @param {CookieKeys|Array<CookieKeys>} keys Cookie keys that have been accepted
 */
const acceptCookies = (keys) => {
  let eventKeys = keys;
  if (!(keys instanceof Array)) {
    eventKeys = [keys];
  }
  eventBus.emit(Events.COOKIES_ACCEPTED, { keys: eventKeys });
};

export default {
  debug,
  acceptCookies,
  cookies: CookieKeys,
};
