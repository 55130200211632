import Parameters from './parameters';
import Dom from './dom';
import logger from './logger';

/**
 * Finds the corresponding installation from a referer url
 * @param {String} referer The referer to find an integration for
 * @returns {Object|undefined} The installation found
 */
function findRegisteredInstallationFromReferer(referer) {
  if (!referer) {
    return false;
  }

  const refererUrl = new URL(referer);

  const initjsHost = refererUrl.searchParams.get('initjs-host');
  if (initjsHost) {
    logger.log('warn', `The InitJS host is overrided by a query parameter to ${initjsHost}`);
    refererUrl.hostname = refererUrl;
  }

  const initjsPath = refererUrl.searchParams.get('initjs-path');
  if (initjsPath) {
    logger.log('warn', `The InitJS path is overridden by ${initjsPath}`);
    refererUrl.pathname = initjsPath;
  }

  return REGISTERED_INSTALLATIONS.find((installation) => {
    let matchesHost = false;

    if (installation.host) {
      const declaredHosts =
        typeof installation.host === 'string' ? [installation.host] : installation.host;
      matchesHost =
        declaredHosts.includes(refererUrl.hostname) || declaredHosts.includes(initjsHost);
    }

    if (matchesHost) {
      logger.log('debug', `Matches hostname: ${refererUrl.hostname}`);
      logger.log('debug', { matchesHost, installation });

      // Checking if the hostname has URI restriction(s)
      // and if so, check if the current URI is authorised
      if (installation.uriRestricted && installation.uriRestricted.length) {
        let authorisedOnCurrentURI = false;
        installation.uriRestricted.forEach((uri) => {
          const regexp = new RegExp(uri);
          if (regexp.test(refererUrl.pathname)) {
            authorisedOnCurrentURI = uri;
          }
        });
        if (authorisedOnCurrentURI) {
          logger.log(
            'debug',
            `Referer hostname and URI authorisation for "${authorisedOnCurrentURI}" found!`
          );
        } else {
          logger.log(
            'debug',
            'Referer hostname found but NOT AUTHORISED via URI restrictions configuration.'
          );
          matchesHost = false;
        }
      } else {
        logger.log('debug', 'Referer hostname and configuration found!');
      }
    }

    if (matchesHost) {
      return matchesHost;
    }

    const matchesQueryParameter =
      installation.queryParameters &&
      Array.from(refererUrl.searchParams.keys())
        .filter((k) => Object.keys(installation.queryParameters).includes(k))
        .some(
          (commonKey) =>
            refererUrl.searchParams.get(commonKey) === installation.queryParameters[commonKey]
        );

    return matchesQueryParameter;
  });
}

/**
 * Checks if the current script is running in an iframe
 */
function isScriptInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

/**
 * Gets data about the client
 * This is mainly use to define what should be loaded
 * and to give them data about the user
 */
export default function getClientData() {
  let currentScriptUrl;
  let acceptedCookies;
  const referer = Parameters.getReferer();
  const parsedUrl = new URL(referer);
  const currentHost = parsedUrl.hostname;
  const currentScript = Dom.getCurrentScript();
  const debugmode = window.pathmotionDebug === true;
  const registeredInstallation = findRegisteredInstallationFromReferer(referer);
  const isInIframe = isScriptInIframe();
  if (currentScript) {
    acceptedCookies = Parameters.getAcceptedCookies(currentScript);
    currentScriptUrl = Dom.getScriptUrl(currentScript);
  }

  return {
    currentHost,
    currentScript,
    currentScriptUrl,
    referer,
    debugmode,
    acceptedCookies,
    registeredInstallation,
    isInIframe,
  };
}
