import stylesWebsite from './style/website.scss';
import rawStylesCta from './style/cta.scss?raw'; // eslint-disable-line
import rawStylesBadge from './style/badge.scss?raw'; // eslint-disable-line
import rawStylesBubble from './style/bubble.scss?raw'; // eslint-disable-line
import rawStylesLargeCta from './style/large-cta.scss?raw'; //eslint-disable-line
import rawStylesFullWindowCta from './style/full-window-cta.scss?raw'; // eslint-disable-line
import ctaHtmlTemplate from './templates/cta.html';
import largeCtaHtmlTemplate from './templates/large-cta.html';
import bubbleHtmlTemplate from './templates/bubble.html';
import badgeHtmlTemplate from './templates/badge.html';
import ctaFullWindowHtmlTemplate from './templates/full-window-cta.html';
import { load as loadPromobox } from '../promobox/index';
import { getFloatingboxUrl } from '../promobox/urls';
import logger from '../init/logger';
import getClientData from '../init/clientData';
import {
  EXPERIMENT_LARGE_CTA,
  EXPERIMENT_VERSION_5,
  LARGE_CTA_DISMISSED_CACHE_KEY,
} from './experiments';

/**
 * This manages the floating platform experiment
 * It has been done to test a product hypothesis on a few clients
 * and contains flaws in the lifecycle, function names and explicit parameters.
 * If switched to production for many clients, this has to be refactored to keep
 * the strict necessary - and make the lifecycle more reliable.
 */
export default {
  eventListener: {},
  clientData: getClientData(),
  /**
   * Initializes a floating injector instance
   * @param {Object} options.document The window.document where insert Floating Platform dom
   */
  init({
    document,
    initialPath,
    debug,
    currentHost,
    app,
    land,
    state,
    style,
    withChatbot,
    withBubbleHighlight,
    largeCta,
  }) {
    if (!document) {
      console.error('No target document provided');
    }

    if (debug) {
      console.warn('Pm RW : debug mode is enabled');
    }

    /**
     * State of the displayed elements
     */
    this.experimentVersion = EXPERIMENT_VERSION_5;
    if (largeCta) {
      this.experimentVersion = EXPERIMENT_LARGE_CTA;
    }

    this.experimentVersionWithChatbot = 'version5-sub-action';
    this.badgeShown = false;
    this.bubbleShown = false;

    this.appboxConfig = {
      app,
      land,
      initialPath,
    };

    this.document = document;
    this.debug = debug || false;
    this.customStyle = style || {};
    this.state = {
      ...state,
      openCount: 0,
      closeCount: 0,
      fullWindowCount: 0,
    };
    this.currentHost = currentHost;

    this.withChatbot = withChatbot;
    this.withBubbleHighlight = withBubbleHighlight;

    /* the DOM Element platform element when it has been inserted into the dom */
    this.platformDomElement = null;

    /* iframe containing the platform */
    this.overlayNode = this.createOverlayNode();
    this.platformNode = this.createPlatformNode();
    this.bubbleNode = this.createBubbleNode();

    /* iframe containing the cta */
    this.ctaNode =
      this.experimentVersion === EXPERIMENT_LARGE_CTA
        ? this.createLargeCtaNode(land, largeCta)
        : this.createCtaNode(land);
    this.ctaFullWindowNode = this.createCtaFullWindowNode();

    this.listenPluginsMessage();
  },

  /**
   * Unload floating platform widget
   * @returns {void}
   */
  unload() {
    logger.log('info', 'Unload floating platform');

    if (this.overlayNode && this.overlayNode.parentNode) {
      this.overlayNode.parentNode.removeChild(this.overlayNode);
    }
    if (this.platformNode && this.platformNode.parentNode) {
      this.platformNode.parentNode.removeChild(this.platformNode);
    }
    if (this.bubbleNode && this.bubbleNode.parentNode) {
      this.bubbleNode.parentNode.removeChild(this.bubbleNode);
    }
    if (this.badgeNode && this.badgeNode.parentNode) {
      this.badgeNode.parentNode.removeChild(this.badgeNode);
    }
    if (this.ctaNode && this.ctaNode.parentNode) {
      this.ctaNode.parentNode.removeChild(this.ctaNode);
    }
    if (this.ctaFullWindowNode && this.ctaFullWindowNode.parentNode) {
      this.ctaFullWindowNode.parentNode.removeChild(this.ctaFullWindowNode);
    }
    this.removeScrollListener();
  },

  /**
   * way to capture eventual messages sent by this or other PM plugins
   */
  listenPluginsMessage() {
    window.addEventListener(
      'message',
      (e) => {
        if (e.data.author !== 'pathmotion') {
          return;
        }
        logger.log(
          'debug',
          `[floatingPlatform] Receiving event "${e.data.event}" message from "${e.data.type}"`
        );
        // …
      },
      false
    );
  },

  /**
   * Entry point of the insertion of the iFrames
   */
  insert() {
    const ctaNode = this.document.body.appendChild(this.ctaNode);

    this.listenScrollToDisplayBadge(() => {
      if (this.withBubbleHighlight && !this.hasDissmissedBubbleBefore()) {
        this.waitAndShowBubble({ showTimeout: 1000 });
      }
    });

    return ctaNode;
  },

  /**
   * True if the user has dismissed the large cta.
   * @returns {boolean}
   */
  hasDismissedLargeCta() {
    if (!window.sessionStorage) {
      return false;
    }

    return window.sessionStorage.getItem(LARGE_CTA_DISMISSED_CACHE_KEY) === '1';
  },

  /**
   * Mark the cta as dismissed so as not to show it again for this session.
   */
  dismissLargeCta() {
    if (!window.sessionStorage) {
      return;
    }

    window.sessionStorage.setItem(LARGE_CTA_DISMISSED_CACHE_KEY, '1');
  },

  /**
   * Reads in local storage if the user has already dismissed the bubble
   */
  hasDissmissedBubbleBefore() {
    if (!window.localStorage) {
      return false;
    }

    return window.localStorage.getItem('floating-bubble-dismissed') === '1';
  },

  /**
   * Waits for a given timeout before showing the bubble
   */
  waitAndShowBubble({ showTimeout = 2000 } = {}) {
    setTimeout(() => {
      this.document.body.appendChild(this.bubbleNode);
    }, showTimeout);
  },

  /**
   * Remove scroll listener
   * @returns void
   */
  removeScrollListener() {
    if (this.eventListener.scroll) {
      this.eventListener.scroll.forEach((cb) =>
        this.document.defaultView.removeEventListener('scroll', cb)
      );
      delete this.eventListener.scroll;
    }
  },

  /**
   * Listens the scroll event to display the badge on it
   */
  listenScrollToDisplayBadge(callback) {
    if (this.withBubbleHighlight) {
      const onScroll = () => {
        if (this.document.defaultView.scrollY > 50) {
          this.document.defaultView.removeEventListener('scroll', onScroll);
          this.document.body.appendChild(this.badgeNode);
          callback();
        }
      };
      this.document.defaultView.addEventListener('scroll', onScroll);
      this.eventListener.scroll = this.eventListener.scroll || [];
      this.eventListener.scroll.push(onScroll);
    }
  },

  /**
   * append style tag to the dom
   * @param {Document} document Document where the style tag should be appended
   * @param {string} style Style content to append
   */
  appendStyleContentTo(document, style) {
    const styleNode = this.document.createElement('style');
    styleNode.type = 'text/css';
    styleNode.innerHTML = style;
    document.body.appendChild(styleNode);
  },

  /**
   * append script tag to the dom
   * @param {Document} document Document where the script tag should be appended
   * @param {string} script Script content
   */
  appendScriptContentTo(document, script) {
    const scriptNode = this.document.createElement('script');
    scriptNode.type = 'text/javascript';
    scriptNode.innerHTML = script;
    document.body.appendChild(scriptNode);
  },

  /**
   *
   * @param land
   * @param ctaInfo
   * {
   *   title: 'title text',
   *   subTitle: 'subtitle text',
   *   image: 'https://testimage.com',
   *   delay: 5000
   * }
   * @returns {*}
   */
  createLargeCtaNode(land, ctaInfo) {
    const iframeNode = this.document.createElement('iframe');
    iframeNode.src = 'about:blank';
    iframeNode.classList.add(stylesWebsite.cta);
    iframeNode.classList.add(
      stylesWebsite[!this.withChatbot ? this.experimentVersion : this.experimentVersionWithChatbot]
    );
    iframeNode.classList.add(stylesWebsite['large-cta-hidden']);

    // TUI (platform id 308) have a special request to disable the CTA on mobile
    // The styling of this matches their website breakpoint, not our general design guidelines
    if (land === '308@Floating') {
      iframeNode.classList.add(stylesWebsite.pmMobileHidden);
    }

    iframeNode.frameborder = 0;
    iframeNode.allowtransparency = 'true';
    iframeNode.scrolling = 'auto';
    iframeNode.horizontalscrolling = 'no';
    iframeNode.verticalscrolling = 'yes';
    iframeNode.seamless = 'seamless';

    iframeNode.onload = () => {
      const doc = iframeNode.contentWindow.document;
      doc.write(
        largeCtaHtmlTemplate
          .replace(
            '%experimentVersion%',
            !this.withChatbot ? this.experimentVersion : this.experimentVersionWithChatbot
          )
          .replace(
            '%ctaLabel%',
            typeof ctaInfo.ctaLabel !== 'undefined' ? ctaInfo.ctaLabel : 'Meet us'
          )
          .replace('%title%', ctaInfo.title)
          .replace('%subtitle%', ctaInfo.subTitle)
      );

      const closeButtonContainer = doc.getElementById('close-button-container');
      closeButtonContainer.style.background = this.customStyle.colorMain;

      const ctaButtonEl = doc.getElementById('cta-label-button');
      ctaButtonEl.style.background = this.customStyle.colorMain;

      const imageContainerEl = doc.getElementById('cta-label-button-background-image');
      imageContainerEl.style.backgroundImage = `url("${ctaInfo.image}")`;

      this.appendStyleContentTo(doc, rawStylesLargeCta);

      const ctaNode = doc.getElementById('large-cta');

      if (this.hasDismissedLargeCta()) {
        return;
      }

      ctaNode.addEventListener('click', (e) => {
        if (
          e.target.classList.contains('close-button-container') ||
          e.target.parentElement.classList.contains('close-button-container')
        ) {
          e.stopPropagation();
          e.preventDefault();

          // Hide the iframe itself
          iframeNode.classList.add(stylesWebsite['large-cta-hidden']);

          this.dismissLargeCta();
          return;
        }

        // Scroll on a fixed iframe dosn't work well on touch screen devices
        // So we open the Platform in a new tab
        if (this.isTouchScreenDevice()) {
          this.togglePlatformNewTab();
        } else {
          this.togglePlatformIframe(iframeNode);
        }
      });

      // show the CTA after a certain delay specified by the client.
      setTimeout(() => {
        iframeNode.classList.remove(stylesWebsite['large-cta-hidden']);

        // allow some time for the browser to render before calculating the height.
        setTimeout(() => {
          // add 16 for the padding offset
          iframeNode.height = iframeNode.contentWindow.document.body.scrollHeight + 16;
        }, 100);
      }, ctaInfo.delay);
    };

    return iframeNode;
  },

  /**
   * Creates a Call To Action iframe node to be injected into the target client page
   * that contain the main cta of the Floating Platform
   */
  createCtaNode(land) {
    const iframeNode = this.document.createElement('iframe');
    iframeNode.src = 'about:blank';
    iframeNode.classList.add(stylesWebsite.cta);
    iframeNode.classList.add(
      stylesWebsite[!this.withChatbot ? this.experimentVersion : this.experimentVersionWithChatbot]
    );

    // TUI (platform id 308) have a special request to disable the CTA on mobile
    // The styling of this matches their website breakpoint, not our general design guidelines
    if (land === '308@Floating') {
      iframeNode.classList.add(stylesWebsite.pmMobileHidden);
    }

    iframeNode.width = '100%';
    iframeNode.height = '100%';
    iframeNode.frameborder = 0;
    iframeNode.allowtransparency = 'true';
    iframeNode.scrolling = 'auto';
    iframeNode.horizontalscrolling = 'no';
    iframeNode.verticalscrolling = 'yes';
    iframeNode.seamless = 'seamless';

    iframeNode.onload = () => {
      const doc = iframeNode.contentWindow.document;
      doc.write(
        ctaHtmlTemplate
          .replace(
            '%experimentVersion%',
            !this.withChatbot ? this.experimentVersion : this.experimentVersionWithChatbot
          )
          .replace(
            '%ctaLabel%',
            typeof this.customStyle.ctaLabel !== 'undefined' ? this.customStyle.ctaLabel : 'Meet us'
          )
      );
      this.appendStyleContentTo(doc, rawStylesCta);

      const ctaNode = doc.getElementById('cta');
      ctaNode.style.background = this.customStyle.colorMain;
      ctaNode.addEventListener('click', () => {
        // Scroll on a fixed iframe dosn't work well on touch screen devices
        // So we open the Platform in a new tab
        if (this.isTouchScreenDevice()) {
          this.togglePlatformNewTab();
        } else {
          this.togglePlatformIframe(iframeNode);
        }
      });
    };

    return iframeNode;
  },

  createBubbleNode() {
    const onClose = () => {
      if (!window.localStorage) {
        return;
      }
      window.localStorage.setItem('floating-bubble-dismissed', '1');
    };

    const iframeNode = this.document.createElement('iframe');
    iframeNode.src = 'about:blank';
    iframeNode.classList.add(stylesWebsite.bubble);
    iframeNode.classList.add(
      stylesWebsite[!this.withChatbot ? this.experimentVersion : this.experimentVersionWithChatbot]
    );
    iframeNode.style.visibility = 'hidden';
    iframeNode.frameborder = 0;
    iframeNode.allowtransparency = 'true';
    iframeNode.scrolling = 'auto';
    iframeNode.horizontalscrolling = 'no';
    iframeNode.verticalscrolling = 'yes';
    iframeNode.seamless = 'seamless';

    iframeNode.onload = () => {
      const doc = iframeNode.contentWindow.document;

      const bubbleHtmlTemplateWithLabel = bubbleHtmlTemplate
        .replace(
          '%experimentVersion%',
          !this.withChatbot ? this.experimentVersion : this.experimentVersionWithChatbot
        )
        .replace(
          '%bubbleLabel%',
          typeof this.customStyle.bubbleLabel !== 'undefined'
            ? this.customStyle.bubbleLabel
            : 'Meet our people, ask them questions and explore their stories.'
        );
      doc.body.innerHTML = bubbleHtmlTemplateWithLabel;
      this.appendStyleContentTo(doc, rawStylesBubble);

      setTimeout(() => {
        iframeNode.style.height = `${doc.body.offsetHeight + 2}px`;
        iframeNode.style.width = `${doc.body.offsetWidth + 2}px`;
        iframeNode.style.visibility = 'visible';

        const closeNode = doc.getElementById('close');
        closeNode.addEventListener('click', () => {
          iframeNode.classList.add(stylesWebsite.closed);
          onClose();
        });
      }, 300);
    };

    return iframeNode;
  },

  createBadgeNode() {
    const iframeNode = this.document.createElement('iframe');
    iframeNode.src = 'about:blank';
    iframeNode.classList.add(stylesWebsite.badge);
    iframeNode.classList.add(
      stylesWebsite[!this.withChatbot ? this.experimentVersion : this.experimentVersionWithChatbot]
    );
    iframeNode.width = '100%';
    iframeNode.height = '100%';
    iframeNode.frameborder = 0;
    iframeNode.allowtransparency = 'true';
    iframeNode.scrolling = 'auto';
    iframeNode.horizontalscrolling = 'no';
    iframeNode.verticalscrolling = 'yes';
    iframeNode.seamless = 'seamless';

    iframeNode.onload = () => {
      const doc = iframeNode.contentWindow.document;
      doc.write(
        badgeHtmlTemplate.replace(
          '%experimentVersion%',
          !this.withChatbot ? this.experimentVersion : this.experimentVersionWithChatbot
        )
      );
      this.appendStyleContentTo(doc, rawStylesBadge);
      const innerNode = doc.getElementById('badge');

      innerNode.addEventListener('click', () => {
        if (this.isTouchScreenDevice()) {
          this.togglePlatformNewTab();
        } else {
          this.togglePlatformIframe(iframeNode);
        }
      });
    };

    return iframeNode;
  },

  /**
   * Creates a Call To Action iframe node to be injected into the target client page
   * that contain the main cta of the Floating Platform
   */
  createCtaFullWindowNode() {
    const iframeNode = this.document.createElement('iframe');
    iframeNode.src = 'about:blank';
    iframeNode.classList.add(stylesWebsite.ctafullwindow);
    iframeNode.classList.add(
      stylesWebsite[!this.withChatbot ? this.experimentVersion : this.experimentVersionWithChatbot]
    );
    iframeNode.width = '100%';
    iframeNode.height = '100%';
    iframeNode.frameborder = 0;
    iframeNode.allowtransparency = 'true';
    iframeNode.scrolling = 'auto';
    iframeNode.horizontalscrolling = 'no';
    iframeNode.verticalscrolling = 'yes';
    iframeNode.seamless = 'seamless';

    iframeNode.onload = () => {
      const openFullSizeLabel = this.customStyle.openFullSizeLabel
        ? this.customStyle.openFullSizeLabel
        : 'Open in a full-size window';
      const doc = iframeNode.contentWindow.document;
      doc.write(ctaFullWindowHtmlTemplate.replace('%openFullSizeLabel%', openFullSizeLabel));
      this.appendStyleContentTo(doc, rawStylesFullWindowCta);
      const ctaFullWindowNode = doc.getElementById('full-window-cta');

      ctaFullWindowNode.style.background = this.customStyle.colorMain;

      ctaFullWindowNode.addEventListener('click', () => {
        this.state.fullWindowCount += 1;
        this.toggleAllElements();
        setTimeout(() => {
          this.togglePlatformNewTab();
        }, 500);
      });
    };

    return iframeNode;
  },

  getIframeTargetUrl() {
    return getFloatingboxUrl({
      // eslint-disable-line
      referer: this.document.location.href,
      appUrl: this.appboxConfig.app,
      land: this.appboxConfig.land,
      refererLocation: this.document.location,
      initialPath: this.appboxConfig.initialPath,
    });
  },

  /**
   * Detects if the web browser is running on a touch screen device
   */
  isTouchScreenDevice() {
    return 'ontouchstart' in window || navigator.msMaxTouchPoints;
  },

  /**
   * Shows the Platform in a new tab
   */
  togglePlatformNewTab() {
    window.open(this.getIframeTargetUrl(), '_blank');
  },

  /**
   * Shows or hides the Platform in an iFrame
   * @param {*} iframeNode
   * @param {*} ctaNode
   */
  togglePlatformIframe(ctaNode) {
    if (this.platformDomElement === null) {
      this.platformDomElement = this.insertPlatformNode(ctaNode);
      loadPromobox();
      this.document.body.appendChild(this.ctaFullWindowNode);
    }

    this.toggleAllElements();
    const isOpened = this.platformNode.classList.contains(stylesWebsite.opened);

    if (!isOpened) {
      window.postMessage({ author: 'pathmotion', type: 'floatingPlatform', event: 'close' }, '*');
      this.state.closeCount += 1;
    } else {
      window.postMessage({ author: 'pathmotion', type: 'floatingPlatform', event: 'open' }, '*');
      this.state.openCount += 1;
    }
  },

  /**
   * Shows or hides the Platform in an iFrame
   * @param {*} iframeNode
   * @param {*} ctaNode
   */
  toggleAllElements() {
    if (this.platformNode) {
      this.platformNode.classList.toggle(stylesWebsite.opened);
    }
    if (this.ctaFullWindowNode) {
      this.ctaFullWindowNode.classList.toggle(stylesWebsite.opened);
    }
    if (this.overlayNode) {
      this.overlayNode.classList.toggle(stylesWebsite.opened);
    }
    if (this.ctaNode) {
      this.ctaNode.classList.toggle(stylesWebsite.opened);
    }
    if (this.badgeNode) {
      this.badgeNode.classList.add(stylesWebsite.resolved);
    }
    if (this.bubbleNode) {
      this.bubbleNode.classList.add(stylesWebsite.closed);
    }
    if (this.ctaNode) {
      const elementId = this.experimentVersion === EXPERIMENT_LARGE_CTA ? 'large-cta' : 'cta';
      this.ctaNode.contentWindow.document.getElementById(elementId).classList.toggle('opened');
    }
  },

  /**
   * Creates an platform iframe to be injected into the target client page
   * that contain the Platform page
   */
  createPlatformNode() {
    const container = this.document.createElement('div');
    container.classList.add(stylesWebsite.floatingPlatform);
    container.classList.add(
      stylesWebsite[!this.withChatbot ? this.experimentVersion : this.experimentVersionWithChatbot]
    );

    const loadingSpinner = this.document.createElement('div');
    loadingSpinner.classList.add(stylesWebsite.floatingPlatformLoadingSpinner);
    container.appendChild(loadingSpinner);

    const appboxUrl = this.getIframeTargetUrl();
    const iframeNode = this.document.createElement('iframe');
    iframeNode.src = appboxUrl;
    iframeNode.width = '100%';
    iframeNode.height = '100%';
    iframeNode.frameborder = 0;
    iframeNode.allowtransparency = 'true';
    iframeNode.scrolling = 'auto';
    iframeNode.horizontalscrolling = 'no';
    iframeNode.verticalscrolling = 'yes';
    iframeNode.seamless = 'seamless';
    iframeNode.style.height = '100%';
    iframeNode.style.position = 'absolute';
    iframeNode.style.zIndex = '2';
    container.appendChild(iframeNode);

    return container;
  },

  /**
   * Creates a node to make an overlay in the dom
   */
  createOverlayNode() {
    const node = this.document.createElement('div');
    node.classList.add(stylesWebsite.floatingPlatformOverlay);
    return node;
  },

  /**
   * Inserts the iframe Platform node next to the CTA iframe dom element
   * @param {DomElement} ctaNode The CTA iframe node
   * @returns {DomElement} The newly inserted iframe Platform element
   */
  insertPlatformNode(ctaNode) {
    ctaNode.parentNode.insertBefore(this.overlayNode, ctaNode);

    return ctaNode.parentNode.insertBefore(this.platformNode, ctaNode);
  },
};
