/* global REGISTERED_INSTALLATIONS */
import Parameters from './parameters';
import chatbotPmInjector from '../chatbotPmInjector/index';
import floatingPlatform from '../floatingPlatform/index';
import { load as loadPromobox } from '../promobox/index';

import logger from './logger';

import Dom from './dom';
import eventBus from './eventBus';
import getClientData from './clientData';

import Events from '../externalApi/events';
import CookieKeys from '../externalApi/cookieKeys';

/**
 * Enum of the loadable plugins from initjs
 */
const Plugins = {
  COSTIGAN: 'COSTIGAN',
  CHATEXPERIMENTSINJECTOR: 'CHATEXPERIMENTSINJECTOR',
  PMCHATBOTINJECTOR: 'PMCHATBOTINJECTOR',
  PROMOBOX: 'PROMOBOX',
  FLOATING: 'FLOATING',
  CHATBOT_AND_FLOATING: 'CHATBOT_AND_FLOATING_INJECTOR',
};

/**
 * @module init
 *
 * Allows the injection of needed plugins into an external website dom
 * at runtime
 */
export default {
  /**
   * All the script client data (script node, browser language...)
   */
  clientData: {},
  pluginsToLoad: [],

  /**
   * Sets up Init.JS into the page
   */
  init() {
    logger.log('debug', 'Starting to initialize InitJS');

    this.clientData = { ...this.clientData, ...getClientData() };
    logger.log('debug', 'Client data is ', { clientData: this.clientData });

    this.clientData.acceptedCookies.forEach((cookieKey) =>
      this.getState().acceptedCookies.add(cookieKey)
    );
    logger.log(
      'debug',
      'Accepted cookies from data-accepted-cookies attribute are',
      this.clientData.acceptedCookies
    );
    logger.log('debug', 'Accepted cookies in state are', this.getState().acceptedCookies);
    this.checkAcceptedCookies();

    logger.log('debug', 'Initial loading of the plugins.');
    this.loadPlugins();
    eventBus.on(Events.COOKIES_ACCEPTED, (e) => {
      logger.log('debug', 'Cookies accepted through an event.', {
        keys: e.keys,
      });
      e.keys.forEach((cookieKey) => this.getState().acceptedCookies.add(cookieKey));
      this.checkAcceptedCookies();

      this.loadPlugins();
    });
    this.listenPluginsMessage();
    Parameters.onRefererChange(() => {
      logger.log('debug', 'Referer changed.');
      this.clientData = { ...this.clientData, ...getClientData() };
      this.unLoadPlugins();
      this.loadPlugins();
    });
  },

  /**
   * Unload previously loaded plugins
   * @returns {void}
   */
  unLoadPlugins() {
    for (let index = 0; index < this.pluginsToLoad.length; index++) {
      const plugin = this.pluginsToLoad[index];
      if (plugin === Plugins.FLOATING) {
        floatingPlatform.unload();
        this.getState().loadedPlugins.delete(Plugins.FLOATING);
      }
    }
    this.pluginsToLoad = [];
  },

  /**
   * way to capture eventual messages sent by this or other PM plugins
   */
  listenPluginsMessage() {
    window.addEventListener(
      'message',
      (e) => {
        if (e.data.author !== 'pathmotion') {
          return;
        }
        logger.log('debug', `Receiving event "${e.data.event}" message from "${e.data.type}"`);
        // …
      },
      false
    );
  },

  /**
   * Handles the loading of the needed plugins
   * of the page
   */
  loadPlugins() {
    logger.log('debug', 'Reloading plugins. Waiting for the window to be fully loaded');

    this.runWhenWindowLoaded(() => {
      logger.log('debug', 'Window fully loaded. Loading plugins.');

      // pre list all plugins that would have to be loaded
      Object.keys(Plugins).forEach((plugin) => {
        if (this.shouldLoadPlugin(this.clientData, plugin)) {
          this.pluginsToLoad.push(plugin);
        }
      });

      logger.log('debug', `=> ${this.pluginsToLoad} plugin(s) should be loaded`);

      // Initialise each plugin that has to be enabled
      this.pluginsToLoad.forEach((plugin) => {
        logger.log('debug', `==> ${plugin} is loading`);
        this.loadPlugin.call(this, plugin, this.clientData);
      });

      logger.log('debug', 'Finished loading plugins.');
    });
  },

  /**
   * Loads one plugin, saves a reference to it into this.loadedPlugins
   */
  loadPlugin(plugin, clientData) {
    logger.log('debug', `Loading plugin ${plugin} with clientData`, {
      clientData,
    });

    if (plugin === Plugins.PMCHATBOTINJECTOR) {
      chatbotPmInjector
        .init({
          document: window.document,
          abTestingAssignments: clientData.abTestingAssignments,
          registeredInstallation: {
            installationName: clientData.registeredInstallation.installationName,
            installationHash: window.btoa(JSON.stringify(clientData.registeredInstallation)),
          },
          cta: {
            icon: 'icon',
            margin: clientData.registeredInstallation.chatbot_cta_margin || '0px',
          },
          introPromp: clientData.registeredInstallation.introductoryPrompt,
          language: clientData.registeredInstallation.language,
          color: clientData.registeredInstallation.colorChatbot,
          url: Object.keys(clientData.registeredInstallation.pmChatbot),
        })
        .then(() => {
          this.getState().loadedPlugins.set(
            plugin,
            chatbotPmInjector.insert.apply(chatbotPmInjector)
          );
        });
    }

    if (plugin === Plugins.COSTIGAN) {
      if (process.env.COSTIGAN_SCRIPT_URL) {
        this.getState().loadedPlugins.set(
          plugin,
          Dom.appendScriptToBody({
            src: process.env.COSTIGAN_SCRIPT_URL,
            async: true,
            defer: true,
          })
        );
      } else {
        logger.log(
          'error',
          'COSTIGAN_SCRIPT_URL environment is not set. Unable to load costigan external script.'
        );
      }
    }

    if (plugin === Plugins.PROMOBOX) {
      this.getState().loadedPlugins.set(plugin, loadPromobox());
    }

    if (plugin === Plugins.FLOATING) {
      const parsedReferer = new URL(clientData.referer);

      floatingPlatform.init({
        initialPath:
          parsedReferer.searchParams.get('app_data') ||
          clientData.registeredInstallation.initialPath,
        document: window.document,
        debug: clientData.debugmode,
        currentHost:
          clientData.currentHost ||
          JSON.stringify(clientData.registeredInstallation.queryParameters),
        app: clientData.registeredInstallation.floatingApp,
        land: clientData.registeredInstallation.floatingLand,
        largeCta: clientData.registeredInstallation.largeCta,
        state: this.getState(),
        style: {
          colorMain: clientData.registeredInstallation.colorFloating,
          ctaLabel: clientData.registeredInstallation.ctaLabel,
          bubbleLabel: clientData.registeredInstallation.bubbleLabel,
          openFullSizeLabel: clientData.registeredInstallation.openFullSizeLabel,
        },
        withBubbleHighlight: window && window.localStorage.getItem('bubble-dismissed') === '1',
      });
      this.getState().loadedPlugins.set(plugin, floatingPlatform.insert.apply(floatingPlatform));
    }

    logger.log('debug', `Finished to load plugin ${plugin}`);
    logger.log('debug', 'Currently loaded plugins are', {
      loadedPlugins: this.getState().loadedPlugins,
    });
  },

  /**
   * Determines if a plugin should be loaded
   * @param {object} clientData   Webbrowser data to take the decision of loading or not
   * @param {string} plugin       Name of the plugin to be loaded
   * @returns {boolean}           True if the plugin should be loaded
   */
  shouldLoadPlugin(clientData, plugin) {
    logger.log('debug', `Testing if plugin ${plugin} should be loaded`);

    if (this.getState().loadedPlugins.has(plugin)) {
      logger.log('debug', `Plugin ${plugin} already loaded, aborting`);
      return false;
    }

    if (plugin === Plugins.COSTIGAN) {
      if (this.getState().acceptedCookies.has(CookieKeys.CATEGORY_FUNCTIONAL)) {
        return true;
      }
      return false;
    }

    if (plugin === Plugins.PROMOBOX) {
      return (
        !clientData.isInIframe &&
        (window.document.getElementsByClassName('_pathmotion_plugin_js').length > 0 ||
          window.document.getElementsByClassName('pathmotion-with-promobox').length > 0)
      );
    }

    return this.shouldLoadExperimentalPlugins(clientData, plugin);
  },

  /**
   * Determines if an experiment plugin should be loaded
   * @param {object} clientData   Webbrowser data to take the decision of loading or not
   * @param {string} plugin       Name of the plugin to be loaded
   * @returns {boolean}           True if the plugin should be loaded
   */
  shouldLoadExperimentalPlugins(clientData, plugin) {
    logger.log('debug', `Testing if any experimental plugin ${plugin} should be loaded`);
    const { registeredInstallation } = clientData;

    if (plugin === Plugins.PMCHATBOTINJECTOR) {
      return !clientData.isInIframe && registeredInstallation && registeredInstallation.pmChatbot;
    }

    if (plugin === Plugins.FLOATING) {
      return (
        !clientData.isInIframe &&
        registeredInstallation &&
        registeredInstallation.colorFloating &&
        clientData.currentHost !== registeredInstallation.floatingApp &&
        Array.from(window.document.getElementsByClassName('_pathmotion_plugin_js')).filter(
          (elem) => elem.getAttribute('data-type') === '_pmappbox'
        ).length === 0 &&
        Array.from(window.document.getElementsByClassName('pathmotion-with-promobox')).filter(
          (elem) => elem.getAttribute('data-type') === '_pmappbox'
        ).length === 0 &&
        registeredInstallation.colorFloating !== undefined &&
        registeredInstallation.floatingApp !== undefined &&
        registeredInstallation.floatingLand !== undefined
      );
    }

    return false;
  },

  /**
   * Runs something when the windows will load or now
   * if it has already loaded
   *
   * @param {Function} runnable The code to execute on loaded
   */
  runWhenWindowLoaded(runnable) {
    if (document.readyState === 'complete') {
      runnable();
    } else {
      window.addEventListener('load', runnable);
    }
  },

  /**
   * Checks that all accepted cookies are known and valid ones.
   * This is made to avoid typo mistakes by external developers
   * and to make debug easier.
   */
  checkAcceptedCookies() {
    const supportedKeys = Object.values(CookieKeys);
    const unknownKeys = [...this.getState().acceptedCookies].filter(
      (key) => !supportedKeys.includes(key)
    );

    if (unknownKeys && unknownKeys.length) {
      logger.log('warn', 'Some cookies acceptance keys are unknown. Check the spelling.', {
        unknownKeys,
        supportedKeys,
      });
    }
  },

  /**
   * Gets a common state object for all the instances of
   * init js
   */
  getState() {
    if (window.pathmotionInitJsState === undefined) {
      window.pathmotionInitJsState = {
        /**
         * Stores all the plugins that have already been loaded
         */
        loadedPlugins: new Map([]),

        /**
         * Stores all the cookies keys that have been accepted
         */
        acceptedCookies: new Set([]),
      };
    }

    return window.pathmotionInitJsState;
  },
};
