import { v4 as uuidv4 } from 'uuid';
import logger from '../init/logger';
import tracker, { events } from './tracker';

import stylesCta from './style/cta.scss?raw'; // eslint-disable-line
import stylesCtaIframe from './style/cta.iframe.scss';
import stylesIntroductoryPrompt from './style/introductory-prompt.scss?raw'; // eslint-disable-line
import stylesIntroductoryPromptIframe from './style/introductory-prompt.iframe.scss';
import stylesChatIframe from './style/chat.iframe.scss';
import ctaHtmlTemplate from './templates/cta.html';
import introductoryEnPromptHtmlTemplate from './templates/introductory-prompt.en.html';
import introductoryFrPromptHtmlTemplate from './templates/introductory-prompt.fr.html';
import introductoryFrSchoolPromptHtmlTemplate from './templates/introductory-prompt.fr-school.html';

const searchParamsToObject = (searchParams) => {
  if (Object.prototype.hasOwnProperty.call(Object, 'fromEntries')) {
    return Object.fromEntries(searchParams);
  }
  const tmp = Array.from(searchParams.entries());
  const result = {};
  for (let index = 0; index < tmp.length; index++) {
    const element = tmp[index];
    // eslint-disable-next-line prefer-destructuring
    result[element[0]] = element[1];
  }
  return result;
};

export default {
  nodes: {},

  state: 'close',

  set opened(val) {
    const newState = val ? 'open' : 'close';

    if (this.state === newState) {
      return false;
    }
    if (val) {
      this.cta.contentWindow.document.querySelector('#cta').classList.add('opened');
      this.removeNodes('introductoryPrompt');
      this.openChatbot();
    } else {
      this.cta.contentWindow.document.querySelector('#cta').classList.remove('opened');
      this.closeChatbot();
    }
    this.state = newState;
    return true;
  },

  toggle() {
    this.opened = this.state === 'close';
  },

  /**
   * Initializes pathmotion chatbot instance
   * @param {Object} options.document The window.document where insert chatbot dom
   */
  init({ document, cta, introPromp, color, url, language, registeredInstallation }) {
    logger.log('info', '[Pathmotion Chatbot] initializing.');
    logger.log('info', `[Pathmotion Chatbot] color: ${color}`);
    this.chatbotUrl = this.buildChatbotUrl(url, { color });
    this.botVersion = this.getBotVersion(this.chatbotUrl);
    logger.log('debug', `[Pathmotion Chatbot] url: ${this.chatbotUrl}`);
    logger.log('debug', `[Pathmotion Chatbot] botVersion: ${this.botVersion}`);
    this.chatSession = Object.prototype.hasOwnProperty.call(
      window.localStorage,
      'pm_chatbot_session_id'
    )
      ? window.localStorage.getItem('pm_chatbot_session_id')
      : uuidv4();
    this.document = document;
    return tracker
      .init(this.chatSession, this.botVersion, {
        ...registeredInstallation,
      })
      .then(() => {
        this.chatbotUrl = this.addTrackingIdToURL(this.chatbotUrl, tracker.trackingId);
        this.cta = this.createCtaNode({ ...cta, color });
        this.nodes.cta = [this.createStyleNode(stylesCtaIframe), this.cta];
        let enableIntroPrompt = true;
        if (
          Object.prototype.hasOwnProperty.call(window.localStorage, 'pm_chatbot_skip_intro_prompt')
        ) {
          const skippedDate = new Date(window.localStorage.getItem('pm_chatbot_skip_intro_prompt'));
          enableIntroPrompt = new Date().getTime() - skippedDate.getTime() > 3.6e6; // 1 hour
        }
        if (enableIntroPrompt) {
          this.nodes.introductoryPrompt = [
            this.createStyleNode(stylesIntroductoryPromptIframe),
            this.createIntroductoryPromptNode({
              ...introPromp,
              color,
              language,
              margin: cta.margin,
            }),
          ];
        }
      });
  },

  /**
   * Insert pathmotion chatbot instance
   * @returns {void}
   */
  insert() {
    logger.log('info', '[Pathmotion Chatbot] inserting HTML element(s)...');

    Object.keys(this.nodes).forEach((category) => {
      logger.log(
        'info',
        `[Pathmotion Chatbot] ${category} will insert ${this.nodes[category].length} node(s)`
      );
      this.nodes[category].forEach((node) => {
        this.document.body.appendChild(node);
      });
    });
  },

  /**
   * Remove HTML nodes Elements
   * @param {string|undefined} category
   * @returns {bool}
   */
  removeNodes(category) {
    if (!category) {
      Object.keys(this.nodes).forEach((nodeCategory) => this.removeNodes(nodeCategory));
      return true;
    }
    if (!this.nodes[category]) {
      logger.log('info', `[Pathmotion Chatbot] HTML node element ${category} does not exist`);
      return false;
    }
    logger.log('info', `[Pathmotion Chatbot] Removing HTML node element ${category}`);
    this.nodes[category].forEach((el) => {
      if (el.parentNode) {
        el.parentNode.removeChild(el);
      }
    });
    delete this.nodes[category];
    return true;
  },

  getChatbotSessionId() {
    if (!Object.prototype.hasOwnProperty.call(window.localStorage, 'pm_chatbot_session_id')) {
      const sessionId = uuidv4();
      window.localStorage.setItem('pm_chatbot_session_id', sessionId);
    }
    return window.localStorage.getItem('pm_chatbot_session_id');
  },

  buildChatbotUrl(url, options) {
    const { origin, pathname, searchParams } = new URL(url);
    const qs = {
      ...searchParamsToObject(searchParams),
      chatSession: this.getChatbotSessionId(),
      ...(options.color && { color: options.color.replace('#', '') }),
      ...(options.trackingId && { trackingId: options.trackingId }),
    };
    const inlineQs = Object.keys(qs)
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(qs[k])}`)
      .join('&');
    return `${origin}${pathname}?${inlineQs}`;
  },

  /**
   * Parse a bot version id from the given url.
   * @param {string} url Chatbot url.
   * @return {string} Bot version id.
   */
  getBotVersion(url) {
    const { searchParams } = new URL(url);
    const qs = searchParamsToObject(searchParams);
    return qs.botVersion;
  },

  /**
   * Add a tracking id to the chatbot URL
   * @param {string} url Chatbot URL
   * @param {*} trackingId Tracking id
   * @return {string} Chatbot URL with a tracking id
   */
  addTrackingIdToURL(url, trackingId) {
    return this.buildChatbotUrl(url, { trackingId });
  },

  /**
   * Open chatbot window
   * @return {void}
   */
  openChatbot() {
    this.cta.contentWindow.document.querySelector('#cta').classList.add('opened');

    const iframeNode = this.createIframeNode();
    iframeNode.classList.add(stylesChatIframe.iframe);
    iframeNode.classList.add(stylesChatIframe['position-bottom-right']);
    iframeNode.src = this.chatbotUrl;

    this.document.body.appendChild(iframeNode);
    this.nodes.chatbot = [iframeNode];
    tracker.trackEvent(events.EVENT_CHATBOT_OPEN);
  },

  /**
   * Close chatbot window
   * @return {void}
   */
  closeChatbot() {
    this.removeNodes('chatbot');
    tracker.trackEvent(events.EVENT_CHATBOT_CLOSE);
  },

  /**
   * Create chatbot CTA HTML Node
   * @param {any} options
   * @returns {HTMLIFrameElement}
   */
  createCtaNode(options) {
    logger.log('info', '[Pathmotion Chatbot] Creation of the chatbot CTA');
    logger.log('info', `[Pathmotion Chatbot] CTA color: ${options.color}`);
    logger.log('info', `[Pathmotion Chatbot] CTA icon: ${options.icon}`);
    logger.log('info', `[Pathmotion Chatbot] CTA margin: ${options.margin}`);

    const iframeNode = this.createIframeNode();
    iframeNode.classList.add(stylesCtaIframe.iframe);
    iframeNode.classList.add(stylesCtaIframe['position-bottom-right']);
    iframeNode.style.margin = options.margin;

    iframeNode.onload = () => {
      const doc = iframeNode.contentWindow.document;
      doc.write(
        ctaHtmlTemplate.replace('%ctaIcon%', options.icon).replace('%ctaLabel%', 'chatbot')
      );
      doc.body.appendChild(this.createStyleNode(stylesCta));
      tracker.trackEvent(events.EVENT_CTA_SHOW);

      const innerNode = doc.getElementById('cta');
      innerNode.style.background = options.color;
      innerNode.addEventListener('click', () => {
        tracker.trackEvent(events.EVENT_CTA_CLICK);
        this.toggle();
      });
    };
    return iframeNode;
  },

  /**
   * Create chatbot introductory prompt
   * @param {any} options
   * @returns {HTMLIFrameElement}
   */
  createIntroductoryPromptNode(options) {
    logger.log('info', '[Pathmotion Chatbot] Creation of the chatbot introductory prompt');
    logger.log('info', `[Pathmotion Chatbot] introductory prompt color: ${options.color}`);
    logger.log(
      'info',
      `[Pathmotion Chatbot] introductory prompt assistant-name: ${options.assistantName}`
    );
    logger.log(
      'info',
      `[Pathmotion Chatbot] introductory prompt client-name: ${options.clientName}`
    );
    logger.log('info', `[Pathmotion Chatbot] introductory prompt margin: ${options.margin}`);

    const iframeNode = this.createIframeNode();
    iframeNode.classList.add(stylesIntroductoryPromptIframe.iframe);
    iframeNode.classList.add(stylesIntroductoryPromptIframe['position-bottom-right']);
    iframeNode.style.margin = options.margin;

    iframeNode.onload = () => {
      const doc = iframeNode.contentWindow.document;
      doc.write(
        this.getIntroductoryPromptHtmlTemplate(options.language)
          .replace('%assistant-name%', options.assistantName)
          .replace('%client-name%', options.clientName)
      );
      doc.body.appendChild(this.createStyleNode(stylesIntroductoryPrompt));
      tracker.trackEvent(events.EVENT_INTRO_PROMPT_SHOW);
      doc.body.querySelector('.cta-container button').style.color = options.color;
      doc.body.querySelector('.cta-container button').addEventListener('click', () => {
        tracker.trackEvent(events.EVENT_INTRO_PROMPT_CLICK);
        this.opened = true;
      });
      doc.body.querySelector('button.close').addEventListener('click', () => {
        tracker.trackEvent(events.EVENT_INTRO_PROMPT_CLOSE);
        window.localStorage.setItem('pm_chatbot_skip_intro_prompt', new Date());
        this.removeNodes('introductoryPrompt');
      });
      doc.body.style.background = options.color;
      logger.log(
        'info',
        `[Pathmotion Chatbot] introductory prompt height set to ${doc.body.querySelector('#introductory-prompt').offsetHeight}px`
      );
      iframeNode.style.height = `${doc.body.querySelector('#introductory-prompt').offsetHeight}px`;
    };
    return iframeNode;
  },

  /**
   * Create generic iFrame node element
   * @param {number} height - iframe height in pixel
   * @param {number} width - iframe width in pixel
   * @return {HTMLIFrameElement}
   */
  createIframeNode() {
    logger.log('info', '[Pathmotion Chatbot] Creation of an HTML iframe node element.');
    const iframeNode = this.document.createElement('iframe');
    iframeNode.src = 'about:blank';
    iframeNode.width = '100%';
    iframeNode.height = '100%';
    iframeNode.frameborder = 0;
    iframeNode.allowtransparency = 'true';
    iframeNode.scrolling = 'auto';
    iframeNode.horizontalscrolling = 'no';
    iframeNode.verticalscrolling = 'yes';
    iframeNode.seamless = 'seamless';

    return iframeNode;
  },

  /**
   * Create generic style node element
   * @param {any} style
   * @returns {HTMLStyleElement}
   */
  createStyleNode(style) {
    logger.log('info', '[Pathmotion Chatbot] Creation of an HTML style node element.');
    const styleNode = this.document.createElement('style');
    styleNode.type = 'text/css';
    styleNode.innerHTML = style;

    return styleNode;
  },

  /**
   * Returns the corresponding introductory prompt template.
   * @param {string} language
   * @returns {string}
   */
  getIntroductoryPromptHtmlTemplate(language) {
    logger.log('debug', `Loading "${language || 'default'}" introductory prompt template`);
    switch (language) {
      case 'fr':
        return introductoryFrPromptHtmlTemplate;
      case 'fr-school':
        return introductoryFrSchoolPromptHtmlTemplate;
      case 'en':
      default:
        return introductoryEnPromptHtmlTemplate;
    }
  },
};
