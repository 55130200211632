/* eslint-disable */
import mitt from 'mitt';

import logger from './logger';

/**
 * Handles events
 */
export class EventBus {
  /**
   * Creates or get the instance of the event bus for the window
   */
  getEmitter() {
    if (typeof window.pathmotionEventBus === 'undefined') {
      logger.log('debug', 'window.pathmotionEventBus is not defined - initializing it');
      window.pathmotionEventBus = mitt();
    }
    return window.pathmotionEventBus;
  }

  /**
   * Adds an handler
   * @see https://github.com/developit/mitt
   */
  on(type, handler) {
    logger.log('debug', `Adding an event handler on ${type} event`);
    this.getEmitter().on(type, handler);
  }

  /**
   * Removes an handler
   * @see https://github.com/developit/mitt
   */
  off(type, handler) {
    logger.log('debug', `Removing an event handler on ${type} event`);
    this.getEmitter().off(type, handler);
  }

  /**
   * Emits an event
   * @see https://github.com/developit/mitt
   */
  emit(type, evt) {
    logger.log('debug', `Event ${type} emited`, { evt });
    this.getEmitter().emit(type, evt);
  }
}

export default new EventBus(window);
