import logger from '../init/logger';

export const events = {
  EVENT_CTA_SHOW: 'EVENT_CTA_SHOW',
  EVENT_CTA_CLICK: 'EVENT_CTA_CLICK',
  EVENT_CHATBOT_OPEN: 'EVENT_CHATBOT_OPEN',
  EVENT_CHATBOT_CLOSE: 'EVENT_CHATBOT_CLOSE',
  EVENT_INTRO_PROMPT_SHOW: 'EVENT_INTRO_PROMPT_SHOW',
  EVENT_INTRO_PROMPT_CLOSE: 'EVENT_INTRO_PROMPT_CLOSE',
  EVENT_INTRO_PROMPT_CLICK: 'EVENT_INTRO_PROMPT_CLICK',
};

/**
 * Logger. Add prefix to logs to make it easier to filter.
 *
 * @param {string} level One of 'log', 'info', 'warn', 'error'.
 * @param {string} message Message to log.
 */
const log = (level, message) => {
  logger.log(level, `[Chatbot tracker] ${message}`);
};

/**
 * Get chatbot backend URL.
 *
 * @return {null|string}
 */
const getBackendUrl = () => {
  if (!process.env.CHATBOT_BACKEND_URL) {
    throw new Error('CHATBOT_BACKEND_URL environment variable is not set.');
  }
  return process.env.CHATBOT_BACKEND_URL;
};

/**
 * Get tracking id.
 * @param {string} chatSessionId
 * @param {string} botVersion
 * @param {Object} abTesting
 * @return {Promise}
 */
const getTrackingId = async (chatSessionId, botVersion, abTesting = {}) => {
  let trackingId = window.localStorage.getItem('trackingId');
  if (!trackingId) {
    const response = await fetch(`${getBackendUrl()}/t/init`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        chatSessionId,
        botVersion,
        abTesting,
      }),
    });
    const result = await response.json();

    log('debug', 'getTrackingId() response');
    log('debug', result);

    // eslint-disable-next-line prefer-destructuring
    trackingId = result.trackingId;
    window.localStorage.setItem('trackingId', trackingId);
  }
  return trackingId;
};

const tracker = {
  /**
   * Unique tracking id given from the chatbot api.
   */
  trackingId: null,

  /**
   * Chatbot session id.
   */
  chatSessionId: null,

  /**
   * Whether the tracker has been initialized successfully.
   */
  initialized: false,

  /**
   * Initialize chatbot tracker.
   *
   * @param {string} chatSessionId Chatbot session id.
   * @param {string} botVersion Bot version id.
   * @param {Object} abTesting
   * @return {Promise<void>}
   */
  async init(chatSessionId, botVersion, abTesting) {
    try {
      log('debug', 'Initializing.');
      this.trackingId = await getTrackingId(chatSessionId, botVersion, abTesting);
      this.chatSessionId = chatSessionId;
      this.initialized = true;
      log('debug', `Initialized. ${JSON.stringify(this)}`);
    } catch (e) {
      log(
        'error',
        `An error occurred while initializing the bot. ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`
      );
    }
  },

  /**
   * @param {string} event Event name.
   */
  async trackEvent(event) {
    log('debug', `Track ${event} event`);
    if (this.initialized) {
      try {
        await fetch(`${getBackendUrl()}/t`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            event,
            chatSessionId: this.chatSessionId,
            trackingId: this.trackingId,
          }),
        });
      } catch (e) {
        log(
          'error',
          `An error occurred while sending track data. ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`
        );
      }
    }
  },
};

export default tracker;
