import init from './init/index';
import ExternalApi from './externalApi';

if (typeof window.Pathmotion === 'undefined') {
  window.Pathmotion = ExternalApi;
}

if (process.env.NODE_ENV === 'development') {
  console.warn('NODE_ENV is development, enabling Pathmotion debug');
  ExternalApi.debug.enable();
}

if (window.pathmotionDebug === true) {
  console.warn('window.pathmotionDebug equals true, Pathmotion debug enabled');
}

init.init();
